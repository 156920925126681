/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Banner} from '@servicetitan/design-system';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    h2: "h2",
    pre: "pre",
    code: "code",
    p: "p",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "using-dark-mode",
    style: {
      position: "relative"
    }
  }, "Using dark mode", React.createElement(_components.a, {
    href: "#using-dark-mode",
    "aria-label": "using dark mode permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "code",
    style: {
      position: "relative"
    }
  }, "Code", React.createElement(_components.a, {
    href: "#code",
    "aria-label": "code permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Banner, {
    icon: true,
    status: "warning",
    title: "React component update in progress"
  }, "We are in progress of updating React components to utilize the theme variables."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { AnvilProvider } from '@servicetitan/design-system';\nimport { dark } from '@servicetitan/anvil-themes';\n\nreturn (\n\t<AnvilProvider theme={dark}>\n\t\t<Button>This is a Button</Button>\n\t</AnvilProvider>\n);\n")), "\n", React.createElement(_components.h2, {
    id: "figma",
    style: {
      position: "relative"
    }
  }, "Figma", React.createElement(_components.a, {
    href: "#figma",
    "aria-label": "figma permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Checkout ", React.createElement(_components.a, {
    href: "https://www.figma.com/file/CVSl2Zh9ne0pNzGi8NxtLl/Anvil-Handbook?node-id=1123%3A28629"
  }, "Anvil Handbook"), " on how to switch between dark and light mode in Figma."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "theme-variables",
    style: {
      position: "relative"
    }
  }, "Theme Variables", React.createElement(_components.a, {
    href: "#theme-variables",
    "aria-label": "theme variables permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\ncustomRender: true\n---\nconst variableTable = () => {\n\tfunction cpl(string) {\n    return string.charAt(0).toUpperCase() + string.slice(1);\n  }\n\tconst tableBody = Object.keys(anvilCoreLight.colors).map((group) => {\n    return Object.keys(anvilCoreLight.colors[group]).map((color) => {\n\t\t\tconst colorSplit = color.split(/(?=[A-Z])/).join(\"-\");\n\t\t\tconst hex = rgbToHex(anvilCoreLight.colors[group][color]);\n      return (\n\t\t\t\t<DataList.Row key={`${color}`}>\n\t\t\t\t\t<DataList.Cell>\n\t\t\t\t\t\t<BodyText size=\"small\">\n\t\t\t\t\t\t\t{`${cpl(group)}-${cpl(colorSplit)}`}\n\t\t\t\t\t\t</BodyText>\n\t\t\t\t\t</DataList.Cell>\n\t\t\t\t\t<DataList.Cell>\n\t\t\t\t\t\t<BodyText size=\"small\">\n\t\t\t\t\t\t\t<code>{`$${group}-${colorSplit.toLowerCase()}`}</code>\n\t\t\t\t\t\t</BodyText>\n\t\t\t\t\t</DataList.Cell>\n\t\t\t\t\t<DataList.Cell>\n\t\t\t\t\t\t<BodyText size=\"small\">\n\t\t\t\t\t\t\t<code>{hex.toUpperCase()}</code>\n\t\t\t\t\t\t</BodyText>\n\t\t\t\t\t</DataList.Cell>\n\t\t\t\t\t<DataList.Cell style={{backgroundColor: hex, width: 50}} />\n\t\t\t\t</DataList.Row>\n\t\t\t)\n    });\n  });\n\n\treturn (\n\t\t<DataList\n\t\t\tspacing={1}\n\t\t\theader={['Name', 'Variable', 'HEX(Default)', ''].map(\n\t\t\t\t(value) => (\n\t\t\t\t\t<DataList.HeaderCell\n\t\t\t\t\t\tkey={value}\n\t\t\t\t\t\tname={value}\n\t\t\t\t\t\tchildren={value}\n\t\t\t\t\t/>\n\t\t\t\t)\n\t\t\t)}\n\t\t>\n\t\t\t{tableBody}\n\t\t</DataList>\n\t)\n}\n\nrender (variableTable)\n")), "\n", React.createElement(_components.hr));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
